<template>
    <div class="app-login">
        <v-container px-8 v-if="!signupSent && !error" class="dark--text">
            <form @submit.prevent="submit">
                <v-row>
                    <v-col class="shrink" cols="12">
                        <h2 class="text-no-wrap text-center my-6">Sign Up</h2>
                    </v-col>
                </v-row>

                <profile-form v-model="model" :allowEmailChange="true" :loading="loading">
                    <template v-slot:password>
                        <v-row dense>
                            <v-col cols="12">
                                <hr />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12">
                                <p class="x-small--text text-uppercase"><b>Password</b></p>
                                <v-text-field
                                    ref="password"
                                    v-model="model.password"
                                    :disabled="loading"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                    @click:append="showPassword = !showPassword"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    prepend-inner-icon="mdi-lock-outline"
                                    label=""
                                    placeholder="Please enter your password..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <p class="x-small--text text-uppercase"><b>Confirm Password</b></p>
                                <v-text-field
                                    ref="password"
                                    v-model="model.confirmPassword"
                                    :disabled="loading"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                    @click:append="showPassword = !showPassword"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    prepend-inner-icon="mdi-lock-outline"
                                    label=""
                                    placeholder="Please confirm your password..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                </profile-form>

                <v-row>
                    <v-col cols="12">
                        <v-btn block large color="primary" :loading="loading" :disabled="!isValid" type="submit"
                            >Sign Up</v-btn
                        >
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.login' }" small text color="dark" :disabled="loading">
                            <span class="text-capitalize font-weight-regular">Already have an account?</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>

        <v-container px-8 v-else-if="error" class="dark--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center dark--text">
                        An error occured while trying to sign you up. Please try again.
                    </p>
                    <p class="text-center dark--text mb-0">
                        <b>{{ errorText }}</b>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" :loading="loading" :disabled="disabled" @click="error = false"
                        >Back</v-btn
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block :to="{ name: 'auth.login' }" small text color="dark">
                        <span class="text-capitalize font-weight-regular">Already have an account?</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container px-8 v-else class="primary--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center grey--text">You have signed up successfully!</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" :to="{ name: 'auth.login' }">Back to Log in</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";

import Persona from "@/modules/app-module/persona";

import ProfileForm from "@/components/layout/profile-form.vue";

export default {
    name: "signup",

    mixins: [UserMixin],

    meta: {
        title: "Sign up",
    },

    components: {
        ProfileForm,
    },

    data() {
        return {
            errorText: "",
            loading: false,
            processing: false,
            disabled: false,
            signupSent: false,
            error: false,
            showPassword: false,

            model: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                country: {},
                type: [],
                studentAward: false,
                masterAward: false,
                grandMasterAward: false,
                entrant: "Australian Entrant",
                password: "",
                confirmPassword: "",
                showPassword: false,
            },

            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        isValid() {
            let model = this.model;

            // if (!model || !model.type || !model.type.length) return false;

            if (!model || !model.firstName || !model.firstName.length) return false;

            if (!model || !model.lastName || !model.lastName.length) return false;

            if (!model || !model.email || !model.email.length) return false;

            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(model.email)) return false;

            if (!model || !model.phoneNumber || !model.phoneNumber.length) return false;

            // This is no longer needed
            // if (!model || !model.address || !model.address.length) return false;

            // if (!model || !model.country || !model.country.length) return false;

            if (!model || !model.password || !model.password.length) return false;

            if (!model || !model.confirmPassword || !model.confirmPassword.length) return false;

            if (model?.password !== model?.confirmPassword) return false;

            return true;
        },
    },

    methods: {
        async submit() {
            var self = this;

            let model = self.model;

            //Check if there is an application
            var applicationContext = self.application && self.application.authenticationStyle == "application";

            self.loading = true;

            console.log("Sign up to application");

            let payload = {
                type: model.type,
                firstName: model.firstName,
                lastName: model.lastName,
                username: model.email,
                phoneNumber: model.phoneNumber,
                address: model.address,
                country: model.country,
                facebook: model.facebook || "", // optional
                twitter: model.twitter || "", // optional
                instagram: model.instagram || "", // optional
                website: model.website || "", // optional
                other: model.other || "", // optional
                entrant: model.entrant,
                password: model.password,
                confirmPassword: model.confirmPassword,
            };

            if (self.hasKey("print")) {
                payload.studentAward = model.studentAward;
                payload.masterAward = model.masterAward;
                payload.grandMasterAward = model.grandMasterAward;
            }

            try {
                let signup = await self.$fluro.api
                    .post("/reaction/spark/628c44fc33ce21429b114935", payload)
                    .then(({ data }) => data);

                console.log("SIGNUP", signup);

                self.$fluro.auth
                    .login(
                        {
                            username: self.model.email,
                            password: self.model.password,
                        },
                        {
                            bypassInterceptor: true,
                            application: applicationContext, //Sign in to the application rather than Fluro
                        }
                    )
                    .then(
                        (res) => {
                            var user = res.data;
                            console.log("Login success", user);
                            self.$app.error = {};
                            this.$router.replace({ name: "auth.welcome" }).catch(() => {});
                        },
                        (err) => {
                            var message = self.$fluro.utils.errorMessage(err);

                            self.error = "An error occured while trying to log you in. Please try again.";

                            console.log("ERROR!", message, err);

                            self.loading = false;
                            self.password = "";
                        }
                    );
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorText = err.response.data.error.errorMessage;
                self.loading = false;
            }
        },
        hasKey(key) {
            return this?.model?.type.some((type) => type.key == key);
        },
    },
};
</script>
